<template>
    <div class="swiper-block">
        <swiper ref="mySwiper" @slideChange="onSlideChange" :options="options">
            <swiper-slide slide="1">
                <div class="wrapper inline" halign="between" valign="center">
                    <div class="slide-text">
                        <h1 v-html="$t('common_slide1_title')"></h1>
                        <p v-html="$t('common_slide1_text')"></p>
                    </div>
                    <img class="slide-image" src="~@/assets/images/slider/1.png" />
                </div>
            </swiper-slide>
            <swiper-slide slide="2">
                <div class="wrapper inline" halign="between" valign="center">
                    <div class="slide-text">
                        <h1 v-html="$t('common_slide2_title1')"></h1>
                        <h1 v-html="$t('common_slide2_title2')"></h1>
                        <p v-html="$t('common_slide2_text')"></p>
                    </div>
                    <img class="slide-image" src="~@/assets/images/slider/2.png" />
                </div>
            </swiper-slide>
            <swiper-slide slide="3">
                <div class="wrapper inline" halign="between" valign="center">
                    <div class="slide-text">
                        <h1 v-html="$t('common_slide3_title')"></h1>
                        <p v-html="$t('common_slide3_text')"></p>
                    </div>
                    <img class="slide-image" src="~@/assets/images/slider/3.jpg" />
                </div>
            </swiper-slide>
            <swiper-slide slide="4">
                <div class="wrapper inline" halign="between" valign="center">
                    <div class="slide-text">
                        <h1 v-html="$t('common_slide4_title')"></h1>
                        <p v-html="$t('common_slide4_text')"></p>
                    </div>
                    <img class="slide-image" src="~@/assets/images/slider/4.png" />
                </div>
            </swiper-slide>
        </swiper>
        <div class="wrapper">
            <div class="swiper-pagination">
                <div class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': active == 0}" @click="slideTo(0)"></div>
                <div class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': active == 1}" @click="slideTo(1)"></div>
                <div class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': active == 2}" @click="slideTo(2)"></div>
                <div class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': active == 3}" @click="slideTo(3)"></div>
            </div>
        </div>
    </div>


</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    data () {
      return {
          active: 0,
          options: {
              grabCursor: true,
              autoplay: {
                  delay: 5000,
                  stopOnLastSlide: false,
                  disableOnInteraction: false
              }
          }
      }
    },
    methods: {
        slideTo (id) {
           this.swiper.slideTo(id)
        },
        onSlideChange() {
            this.active = this.swiper.activeIndex
        }
    },
};
</script>